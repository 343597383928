import React, { useState, useRef } from 'react';
import './App.css';

function App() {
  const [message, setMessage] = useState('');
  const contactoRef = useRef(null);

  const handleButtonClick = (productName, imageName) => {
    setMessage(`Hola, me interesa información de ${productName}. ¿Me pueden contactar?`);
    contactoRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div>
      <header>
        <img src="./logo-promoin.jpeg" alt="PromoIn" className="logo" />
        <nav>
          <ul>
            <li><a href="#inicio">Inicio</a></li>
            <li><a href="#productos">Productos</a></li>
            <li><a href="#contacto">Contacto</a></li>
          </ul>
        </nav>
      </header>
      <main>
        <section id="inicio">
          <h1 className="header-title">Artículos promocionales de alta calidad</h1>
        </section>
        <section id="productos">
          <h2>Productos populares</h2>
          <ul>
            <li>
              <h3>Tombstones</h3>
              <img src="reconocimientos.jpeg" alt="Reconocimientos personalizados" className="images" />
              <p>Ofrecemos una amplia variedad de diseños de reconocimientos personalizados. </p>
              <button onClick={() => handleButtonClick('Tombstones', 'reconocimientos.jpeg')}>Cotizar</button>
            </li>
            <li>
              <h3>Kit ejecutivo de vino</h3>
              <img src="kit-vino.jpeg" alt="Kit ejecutivo de vino" className="center" />
              <p>Nuestros kits de vino son perfectos para regalar a tus clientes, colegas y socios de negocios en ocasiones especiales.</p>
              <button onClick={() => handleButtonClick('Kit ejecutivo de vino', 'kit-vino.jpeg')}>Cotizar</button>
            </li>
            <li>
              <h3>Libretas</h3>
              <img src="libretas.jpeg" alt="Libretas" className="center" />
              <p>Personaliza las libretas para potenciar la marca de tus clientes y mantenerse organizados.</p>
              <button onClick={() => handleButtonClick('Libretas', 'libretas.jpeg')}>Cotizar</button>
            </li>
            <li>
              <h3>Camisas polo</h3>
              <img src="camisas-polo.jpeg" alt="Camisas polo" className="center" />
              <p>Playeras polo personalizadas de alta calidad que combinen estilo y branding, permitiendo a tus clientes lucir una imagen profesional y promover su marca.</p>
              <button onClick={() => handleButtonClick('Camisas polo', 'camisas-polo.jpeg')}>Cotizar</button>
            </li>
            <li>
              <h3>Electrónicos como Auriculares de alta calidad</h3>
              <img src="auriculares.jpeg" alt="Auriculares de alta calidad" className="center" />
              <p>Ofrece a tus clientes artículos electrónicos como auriculares, bocinas o airpods. </p>
              <button onClick={() => handleButtonClick('Electrónicos como Auriculares de alta calidad', 'auriculares.jpeg')}>Cotizar</button>
            </li>
          </ul>
        </section>

        <section id="contacto" ref={contactoRef}>
          <h2>Contacto</h2>
          <p>¡Contáctanos para cualquier pregunta o comentario!</p>
          <ul>
            <li>Teléfono: (55) 2980-4391</li>
            <li>Correo electrónico: info@promoin.mx</li>
          </ul>

          <form
            action="https://formsubmit.co/jesusldominguez87@gmail.com"
            method="post"
          >
            <label htmlFor="nombre">Nombre:</label>
            <input type="text" id="nombre" name="name" /><br />
            <label htmlFor="correo">Correo electrónico:</label>
            <input type="email" id="correo" name="email" required/><br />

            <label htmlFor="mensaje">Mensaje:</label>
            <textarea
              id="mensaje"
              name="message"
              rows="4"
              cols="50"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea><br />

            <input type="hidden" name="_subject" value="Nuevo mensaje desde sitio web"></input>
            <input type="hidden" name="_autoresponse" value="Gracias por tu mensaje. En breve nos pondremos en contacto."></input>
            <input type="hidden" name="_next" value="thanks.html"></input>
            <button type="submit">Enviar mensaje</button>
          </form>
        </section>
      </main>
    </div>
  );
}

export default App;
